import React from 'react'

function Lists() {
    return (
        <div className='navleft'>
            <div>
                <h6 style={{ margin: '10px 0' }}>location</h6>
                <div className='navp'>
                    <a href='/projects/dubai'>Dubai</a>
                    <a href='/projects/baghdad'>Baghdad</a>
                    <a href='/projects/erbil'>Erbil</a>
                    <a href='/projects/mosul'>Mosul</a>
                    <a href='/projects/kirkuk'>kirkuk</a>
                    <a href='/projects/turkey'>Turkey</a>
                </div>
            </div>
            <hr />
            <div>
                <h6 style={{ margin: '30px 0 0 0' }}>PROPERTY DEVELOPMENT</h6>
                <div className='navp'>
                    <a href='/samana-skyros'>Samana SkyRos</a>
                    <a href='/Majidi-View'>Majidi View</a>
                    <a href='/alwedd'>alwedd</a>
                    <a href='/khlood'>khlood</a>
                    <a href='/high-class'>High class</a>
                    <a href='/almansour-city'>Almansour</a>
                    <a href='/empire-world'>Empire world</a>
                    <a href='/north-holland'>North Holland</a>
                    <a href='/qaiwan-mirador'>Qaiwan Mirador</a>
                    <a href='/lalav-group'>Lalav Group</a>
                    <a href='/sky-land-kirkuk'>Sky Land</a>
                    <a href='/damac-lagoons'>Damac Lagoons</a>
                    <a href='/azizi-riviera'>Azizi Riviera</a>
                    <a href='/damac-bay2'>Damac Bay 2</a>
                    <a href='/bianca'>Bianca</a>
                    <a href='/sylvana'>Sylvana</a>
                </div>
            </div>
        </div>
    )
}

export default Lists