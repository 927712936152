import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img1 from "../images/rastymahmood.jpg"
import img from "../images/XD1.jpg"
import img3 from "../images/zest.jpg"
import img2 from "../images/rsala.jpg"
import "./about.css"
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet"


 


const About = () => {
  const location = useLocation();
  const paths = window.location.pathname;
      const fword = paths[1] + paths[2]

  return (
    <>
    {fword == 'ar' ? 
      <Helmet>
        <title>عن الشركة - عقارات زيست</title>
        <meta name='description' content="شركة زيست تمتلك 8 فروع في مختلف مناطق العراق وكردستان, عملنا بجد لنضمن افضل تجربة لعملائنا" />
      </Helmet>
      : 
      <Helmet>
        <title>About Us - Zest Property</title>
        <meta name="description" content="We work hard to provide the best experience to our customers, that's why we have 8 branches in different areas of iraq and kurdistan " />
      </Helmet>
      }
      {paths == '/about' ? 
      <>
      <section className='about'>
        <Back name='About Us' title='who are we?' cover={img} />
        <div className='aboutmain'>
          <div className='aboutcontent'>
            <h2>Zest Property Story</h2>
            <p>Zest is owned by IZ-GROUP, which is the leading real estate .</p>
            <p>company in Iraq. and also properties. investments, engineering.</p>
            <p>and project management since 2006.</p>
          </div>
          <div className='aboutimg'>
            <img className="imageabout" src={img3} alt='About Us' />
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='aboutmain'>
          <div className='aboutcontent'>
            <h2>Our vision</h2>
            <p>We offer a wide range of skills and Clever minds with 15 years of experience in field. So we work hard to expand our branches, including our team in Dubai.</p>
          </div>
          <div className='aboutimg'>
            <img className="imageabout" src={img1} alt='About Us' />
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='aboutmain'>
          <div className='aboutcontent'>
            <h2>Our Message</h2>
            <p>we have launched a series of branches in several governorates, including: Erbil, Kirkuk and Baghdad, to provide real estate brokerage services.</p>
          </div>
          <div className='aboutimg'>
            <img className="imageabout" src={img2} alt='About Us' />
          </div>
        </div>
      </section>
      </>
      : 
      <>
        <section className='about'>
          <Back name='عنا' title='من نحن؟' cover={img} />
          <div className='aboutmain'>
            <div className='aboutcontent ar'>
              <h2 title='' subtitle='' >شركة زيست للعقارات الفاخرة مفهوم جديد في عالم الاستثمار</h2>
              <p>احدى شركات أ-ز كروب وهي شركة رائدة في مجال العقارات والاستثمارات وإدارة المشاريع والهندسة منذ عام ٢٠٠٦</p>
              <p>لدينا مجموعة من الخبرات والعقول وبرامج العمل في المشاريع التي عملنا عليها لأكثر من خمسة عشر سنة متواصلة من العمل بين دبي ومحافظات العراق.</p>
              <p>ولأننا نسعى للوصول إلى آفاق جديدة لخدمة المجتمع فقد قمنا بافتتاح سلسلة من الفروع في عدة محافظات منها أربيل وكركوك وبغداد لإدارة الوساطة العقارية.</p>
            </div>
            <div className='aboutimg'>
              <img className="imageabout" src={img3} alt='من نحن؟' />
            </div>
          </div>
        </section>
        <section className='about'>
          <div className='aboutmain'>
            <div className='aboutcontent'>
              <h2 className="ar">الرؤية</h2>
              <p className="ar">العقارات هي روح الاقتصاد في العالم ولنجاح اي عملية عقارية تحتاج لإتخاذ قرار صحيح والى مزيج من الخبرات في مجال الهندسة والاقتصاد والإدارة ثم التسويق الصحيح</p>
            </div>
            <div className='aboutimg'>
              <img className="imageabout" src={img1} alt='من نحن؟' />
            </div>
          </div>
        </section>
        <section className='about'>
          <div className='aboutmain'>
            <div className='aboutcontent'>
              <h2 className="ar">رسالتنا</h2>
              <p className="ar">السير لحياة افضل ووضع جميع خبراتنا في مجال العقارات والاستثمارات لخدمة العملاء</p>
            </div>
            <div className='aboutimg'>
              <img className="imageabout" src={img2} alt='من نحن؟' />
            </div>
          </div>
        </section>
      </>
      }
    </>
  )
}

export default About
