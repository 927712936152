


export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "properties",
    path: "/properties",
  },
  {
    text: "Agents",
    path: "/agents",
  },
  {
    text: "Projects",
    path: "/projects",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    total: "122 Property",
  },
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    total: "122 Property",
  },
  {
    cover: "../images/hero/h2.png",
    name: "House & Villa",
    total: "155 Property",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Apartment",
    total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Office & Studio",
    total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Villa & Condo",
    total: "80 Property",
  },
]


export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-6.png",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/AkamAli.webp",
    address: "project: Sky Land Kirkuk",
    name: "Mr.Akam Ali",
    email: "mailto:akam.a@zest-property.com",
    phone: "tel:+9647501762006",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "50",
    cover: "../images/customer/mriam.jpg",
    address: "project: Erbil",
    name: "Mriam Mufaq",
    email: "mailto:mriam.m@zest-property.com",
    phone: "tel:+9647505622006",
  },
  {
    list: "50",
    cover: "../images/customer/kaywan.webp",
    address: "project: Erbil",
    name: "Kaywan Kayfi",
    email: "mailto:kaiwankaify@zest-property.com",
    phone: "tel:+9647506662006",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "50",
    cover: "../images/customer/rasty.webp",
    address: "project: Erbil",
    name: "Rasty Kaify",
    email: "mailto:Rasty.kayfi@zest-property.com",
    phone: "tel:+9647507342006",
  },
  {
    list: "50",
    cover: "../images/customer/Aya.Alsaab.webp",
    address: "project: Erbil",
    name: "Aya Al-saab",
    email: "mailto:zest@zest-property.com",
    phone: "tel:+9647506322006",
  },


]
export const allAgents = [
  {
    list: "50",
    cover: "../images/customer/mahmood.webp",
    address: "project: Erbil",
    name: "Mahmood Abdullah",
    email: "mailto:'mahmood@zest-property.com'",
    phone: "tel:+9647501882006",
  },
  {
    list: "50",
    cover: "../images/customer/AkamAli.webp",
    address: "project: Sky Land Kirkuk",
    name: "Akam Ali",
    email: "mailto:akam.a@zest-property.com",
    phone: "tel:+9647501762006",
  },
  {
    list: "50",
    cover: "../images/customer/mriam.jpg",
    address: "project: Erbil",
    name: "Mriam Mufaq",
    email: "mailto:mriam.m@zest-property.com",
    phone: "tel:+9647505622006",
  },
  {
    list: "50",
    cover: "../images/customer/abdulla.jpg",
    address: "project: Erbil",
    name: "Abdulla Fars",
    email: "mailto:abdulla.f@zest-property.com",
    phone: "tel:+9647506522006",
  },
  {
    list: "50",
    cover: "../images/customer/kaywan.webp",
    address: "project: Erbil",
    name: "Kaywan Kayfi",
    email: "mailto:kaiwankaify@zest-property.com",
    phone: "tel:+9647506662006",
  },
  {
    list: "50",
    cover: "../images/customer/rasty.webp",
    address: "project: Erbil",
    name: "Rasty Kaify",
    email: "mailto:Rasty.kayfi@zest-property.com",
    phone: "tel:+9647507342006",
  },
  {
    list: "50",
    cover: "../images/customer/shaho.webp",
    address: "project: Kirkuk",
    name: "Shahoo Dieyaree",
    email: "mailto:shahoo.d@zest-property.com",
    phone: "tel:+9647509632006",
  },
  {
    list: "50",
    cover: "../images/customer/M.Sami.webp",
    address: "project: Kirkuk",
    name: "Muhammed Sami",
    email: "mailto:m.sami@zest-property.com",
    phone: "tel:+9647708652006",
  },
  {
    list: "50",
    cover: "../images/customer/Aya.Alsaab.webp",
    address: "project: Erbil",
    name: "Aya Al-saab",
    email: "mailto:aya.s@zest-property.com",
    phone: "tel:+9647506322006",
  },
  {
    list: "50",
    cover: "../images/customer/HozanJumaa.webp",
    address: "project: Kirkuk",
    name: "Hozan Jumaa",
    email: "mailto:hozan.j@zest-property.com",
    phone: "tel:+9647509102006",
  },
  // {
  //   list: "50",
  //   cover: "../images/customer/muhammadmajed.webp",
  //   address: "project: Erbil",
  //   name: "Muhammed Majed",
  //   email: "mailto:mohammad.m@zest-property.com",
  //   phone:"tel:+9647502422006",
  // },
  // {
  //   list: "50",
  //   cover: "../images/customer/muhammedrasul.webp",
  //   address: "project: Erbil",
  //   name: "Mohammad Rasul",
  //   email: "mailto:M.Rasul@zest-property.com",
  //   phone: "tel:+9647506912006",
  // },
  // {
  //   list: "50",
  //   cover: "../images/customer/aliagha.webp",
  //   address: "project: Erbil",
  //   name: "Ali Agha", 
  //   email: "mailto:ali.a@zest-property.com",
  //   phone:"tel:+9647505912006",
  // },
]
export const price = [

]
export const footer = [
  {
    title: "Social",
    text: [{ list: "Facebook", link: 'https://www.facebook.com/zest.real.estate.erbil' }, { list: "Instagram", link: 'https://www.instagram.com/zest_properties/' }, { list: "Youtube", link: 'https://www.youtube.com/channel/UCj1_7eqoDzWn6RFLAj6ScNQ' }, { list: "Telegram", link: 'https://t.me/+UY2vzSdjnmY1Njli' }, { list: "Privacy Policy", link: '/Privacy-Policy' }],
  },
]
export const arfooter = [
  {
    title: "اجتماعي",
    text: [{ list: "فيس بوك", link: 'https://www.facebook.com/zest.real.estate.erbil' }, { list: "ئینستاگرام", link: 'https://www.instagram.com/zest_properties/' }, { list: "یوتوب", link: 'https://www.youtube.com/channel/UCj1_7eqoDzWn6RFLAj6ScNQ' }, { list: "تیلیگرام", link: 'https://t.me/+UY2vzSdjnmY1Njli' }, { list: "الخصوصية والأمان", link: '/Privacy-Policy' }]
  },
]

export const arawards = [
  {
    icon: '../images/logo/user.jpg',
    num: "2 M	",
    name: "المستخدمون",
  },
  {
    icon: '../images/logo/git.jpg',
    num: "8",
    name: "الفرع",
  },
  {
    icon: '../images/logo/house.jpg',
    num: "134",
    name: "كل انواع العقارات",
  },
  {
    icon: '../images/logo/agent.jpg',
    num: "42",
    name: "وكلائنا",
  },
]





export const awards = [
  {
    icon: '../images/logo/user.jpg',
    num: "2 M	",
    name: "users",
  },
  {
    icon: '../images/logo/git.jpg',
    num: "8",
    name: "Branch",
  },
  {
    icon: '../images/logo/house.jpg',
    num: "134",
    name: "All Kinda Property",
  },
  {
    icon: '../images/logo/agent.jpg',
    num: "42",
    name: "Agents",
  },
]

export const listAll = [
  {
  cover:"../images/property/majidi/majidism.jpg",
  url:"apartment-for-sale-majidi-view-83",
  name:"apartment for sale majidi view",
  location:"Erbil",
  category:"buy",
  price:"Call us",
  type:"2 + 1",
  nameproject:"Majidi View",
  number:"1",
  area:"83 m",
  citys:"Erbil",
  property:"Apartment",
  place:"Erbil - koya road",
  id:"646dccb3792e1089f528d554",
  },


  
  {
  cover:"../images/property/bay2/baysm.jpg",
  url:"apartment-for-sale-damac-bay2-72",
  name:"apartment for sale damac bay2 72",
  location:"Dubai",
  category:"buy",
  price:"Call us",
  type:"1 + 1",
  nameproject:"Damac bay2",
  number:"1",
  area:"72 m",
  citys:"Dubai",
  property:"Apartment",
  place:"Dubai - Harbour",
  },
  
  {
    id: 1,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B1-30-Floor",
    name: "Apartment for Sale Sky View B1 30 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$22,620",
    type: "1 + 1",
    nameproject: 'Sky View',
    number: 'B1-30',
    place: 'Erbil - Newroz',
    area: "78 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 43,
    cover: "../images/property/qaywan/qaywan_blog.jpg",
    url: "Apartment-for-Sale-QAIWAN-MIRADOR-B-6-201",
    name: "Apartment for Sale QAIWAN MIRADOR B 6",
    location: "Erbil - Masif Road Pirmam",
    category: "buy",
    price: "$28,305",
    type: "1 + 4",
    nameproject: 'Qaiwan mirador',
    number: 'B-6',
    place: 'Erbil - Dream city',
    area: "201.6 m",
    citys: 'Erbil',
    property: 'apartment'
  },

  {
    id: 4,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B1-12-Floor",
    name: "Apartment for Sale Sky View B1 12 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$23,920",
    type: "1 + 1",
    nameproject: 'Sky View',
    number: 'B1-12',
    place: 'Erbil - Newroz',
    area: "80 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 5,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A1-22-Floor",
    name: "Apartment for Sale Venus Towers A1 22 Floor",
    location: "Erbil",
    category: "buy",
    price: "$16,096",
    type: "2 + 1",
    nameproject: 'Venus Towers',
    number: 'A1-22',
    place: 'Erbil - hawlery new',
    area: "105 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 6,
    cover: "../images/property/nova/ewr.jpg",
    url: "Apartment-for-Sale-Nova-towers-A2-14-Floor",
    name: "Apartment for Sale Nova towers A2 14 Floor",
    location: "Erbil",
    category: "buy",
    price: "$37,000",
    type: "1 + 1",
    nameproject: 'Nova towers',
    number: 'A2-14',
    place: 'Erbil - Ganjan city',
    area: "81 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
  cover:"../images/property/bay2/baysm.jpg",
  url:"apartment-for-sale-damac-bay2-144",
  name:"apartment for sale damac bay2 144",
  location:"Dubai",
  category:"buy",
  price:"Call us",
  type:"3 + 1",
  nameproject:"Damac bay2",
  number:"1",
  area:"144 m",
  citys:"Dubai",
  property:"Apartment",
  place:"Dubai - Harbour",
  },
  {
    id: 6,
    cover: "../images/property/skyland/skylandsm.jpg",
    url: "House-for-Sale-Skyland1",
    name: "house for Sale Skyland type 1 ",
    location: "Kirkuk",
    category: "buy",
    price: "$69,000",
    type: "Villa",
    nameproject: 'Skyland',
    number: 'A',
    place: 'Kirkuk - traffic office',
    area: "200 m",
    citys: 'Kirkuk',
    property: 'house'
  },
  
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-103",
    name:"apartment for sale majidi view 103 meters",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"2 + 1",
    nameproject:"Majidi View",
    number:"2",
    area:"103 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd5a14cce70fbd1e5d8df",
  },
    
  {
    cover:"../images/property/bay2/baysm.jpg",
    url:"apartment-for-sale-damac-bay2-108",
    name:"apartment for sale damac bay2",
    location:"Dubai",
    category:"buy",
    price:"Call us",
    type:"2 + 1",
    nameproject:"Damac bay2",
    number:"1",
    area:"108 m",
    citys:"Dubai",
    property:"Apartment",
    place:"Dubai - Harbour",
    },
    
    
    
    
  
  {
    id: 9,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A1-25-Floor",
    name: "Apartment for Sale Venus Towers A1 25 Floor",
    location: "Erbil",
    category: "buy",
    price: "$10,317",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A1-25',
    place: 'Erbil - hawlery nwe',
    area: "72 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-A-6-floor",
    name: "Apartment for Sale North Holland A 6",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$28,203",
    type: "1 + 3",
    nameproject: 'North Holland',
    number: 'A-6',
    place: 'Erbil - Dream city',
    area: "210 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-D-10-140",
    name: "Apartment for Sale North Holland D 10",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "Call us",
    type: "1 + 3",
    nameproject: 'North Holland',
    number: 'D-10',
    place: 'Erbil - Dream city',
    area: "140 m",
    citys: 'Erbil',
    property: 'apartment'
  },

  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-F-14-140",
    name: "Apartment for Sale North Holland F 14",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "Call us",
    type: "1 + 3",
    nameproject: 'North Holland',
    number: 'F-14',
    place: 'Erbil - Dream city',
    area: "140 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-D-13-140",
    name: "Apartment for Sale North Holland D 13",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "Call us",
    type: "1 + 3",
    nameproject: 'North Holland',
    number: 'D-13',
    place: 'Erbil - Dream city',
    area: "140 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 10,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A1-8-Floor",
    name: "Apartment for Sale Venus Towers A1 8 Floor",
    location: "Erbil",
    category: "buy",
    price: "$11,397",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A1-8',
    place: 'Erbil - hawlery nwe',
    area: "72 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 6,
    cover: "../images/property/skyland/skylandsm.jpg",
    url: "House-for-Sale-Skyland2",
    name: "house for Sale Skyland type 2 ",
    location: "Kirkuk",
    category: "buy",
    price: "$91,000",
    type: "Villa",
    nameproject: 'Skyland',
    number: 'A',
    place: 'Kirkuk - traffic office',
    area: "211 m",
    citys: 'Kirkuk',
    property: 'house'
  },
  {
    id: 6,
    cover: "../images/property/alwedd/2.jpg",
    url: "property-for-Sale-alwedd-1",
    name: "property for Sale alwedd 1 ",
    location: "Baghdad",
    category: "buy",
    price: "Call Us",
    type: "3 + 1",
    nameproject: 'Alwedd',
    number: '1',
    place: 'Baghdad - Al-Amriya',
    area: "180 m",
    citys: 'Baghdad',
    property: 'apartment'
  },
  {
    id: 11,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-A3-31-Floor",
    name: "Apartment for Sale Sky View A3 31 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$35,856",
    type: "2 + 1",
    nameproject: 'Sky View',
    number: 'A3-31',
    place: 'Erbil - Newroz',
    area: "120 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 6,
    cover: "../images/property/klood/khloodsm.jpg",
    url: "property-for-Sale-alkhlood",
    name: "property for Sale alkhlood",
    location: "Baghdad",
    category: "buy",
    price: "Call Us",
    type: "3 + 1",
    nameproject: 'Alkhlood',
    number: '1',
    place: 'Baghdad - Al-hadl ',
    area: "180 m",
    citys: 'Baghdad',
    property: 'apartment'
  },
  {
    id: 13,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B3-37-Floor",
    name: "Apartment for Sale Sky View B3 37 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$38,256",
    type: "2 + 1",
    nameproject: 'Sky View',
    number: 'B3-37',
    place: 'Erbil - Newroz',
    area: "120 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 14,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A2-14-Floor",
    name: "Apartment for Sale Venus Towers A2 14 Floor",
    location: "Erbil",
    category: "buy",
    price: "$13,550",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A2-14',
    place: 'Erbil - hawlery nwe',
    area: "76 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  

  {
    id: 14,
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-518",
    name:"apartment for sale majidi view 518 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"5 + 1",
    nameproject:"Majidi View",
    number:"10",
    area:"518 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },


  {
    id: 15,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B3-22-Floor",
    name: "Apartment for Sale Sky View B3 22 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$38,256",
    type: "2 + 1",
    nameproject: 'Sky View',
    number: 'B3-22',
    place: 'Erbil - Newroz',
    area: "120 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 6,
    cover: "../images/property/skyland/skylandsm.jpg",
    url: "house-for-Sale-Skyland-vip",
    name: "house for Sale Skyland type vip",
    location: "Kirkuk",
    category: "buy",
    price: "$110,000",
    type: "Villa",
    nameproject: 'Skyland',
    number: 'A',
    place: 'Kirkuk - traffic office',
    area: "270 m",
    citys: 'Kirkuk',
    property: 'house'
  },
  {
    id: 16,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A1-13-Floor",
    name: "Apartment for Sale Venus Towers A1 13 Floor",
    location: "Erbil",
    category: "buy",
    price: "$15,904",
    type: "2 + 1",
    nameproject: 'Venus Towers',
    number: 'A1-13',
    place: 'Erbil - hawlery nwe',
    area: "115 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-147",
    name:"apartment for sale majidi view 147 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"2 + 1",
    nameproject:"Majidi View",
    number:"4",
    area:"147 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },


  {
    id: 6,
    cover: "../images/property/alwedd/2.jpg",
    url: "property-for-Sale-alwedd-2",
    name: "property for Sale alwedd 200 ",
    location: "Baghdad",
    category: "buy",
    price: "Call Us",
    type: "4 + 1",
    nameproject: 'Alwedd',
    number: '2',
    place: 'Baghdad - Al-Amriya',
    area: "200 m",
    citys: 'Baghdad',
    property: 'apartment'
  },
  {
    id: 6,
    cover: "../images/property/alwedd/villa.jpg",
    url: "property-for-Sale-alwedd-villa",
    name: "property for Sale alwedd villa ",
    location: "Baghdad",
    category: "buy",
    price: "Call Us",
    type: "Villa",
    nameproject: 'Alwedd',
    number: '3',
    place: 'Baghdad - Al-Amriya',
    area: "300 m",
    citys: 'Baghdad',
    property: 'House'
  },
  {
    id: 17,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B3-7-Floor",
    name: "Apartment for Sale Sky View B3 7 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$23,120",
    type: "1 + 1",
    nameproject: 'Sky View',
    number: 'B3-7',
    place: 'Erbil - Newroz',
    area: "80 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 18,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A2-6-Floor",
    name: "Apartment for Sale Venus Towers A2 6 Floor",
    location: "Erbil",
    category: "buy",
    price: "$12,951",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A2-6',
    place: 'Erbil - hawlery nwe',
    area: "67 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-108",
    name:"apartment for sale majidi view 108 meter",
    location:"Erbil","category":"Buy",
    price:"Call us",
    type:"2 + 1",
    nameproject:"Majidi View",
    number:"3",
    area:"108 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd6af4cce70fbd1e5d8e1"
  },
  {
    id: 19,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-A1-7-Floor",
    name: "Apartment for Sale Sky View A1 7 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$34,400",
    type: "1 + 1",
    nameproject: 'Sky View',
    number: 'A1-7',
    place: 'Erbil - Newroz',
    area: "80 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 20,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A2-5-Floor",
    name: "Apartment for Sale Venus Towers A2 5 Floor",
    location: "Erbil",
    category: "buy",
    price: "$12,951",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A2-5',
    place: 'Erbil - hawlery nwe',
    area: "67 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 21,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B2-6-Floor",
    name: "Apartment for Sale Sky View B2 6 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$23,120",
    type: "1 + 1",
    nameproject: 'Sky View',
    number: 'B2-6',
    place: 'Erbil - Newroz',
    area: "80 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 22,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-B1-14-Floor",
    name: "Apartment for Sale Venus Towers B1 14 Floor",
    location: "Erbil",
    category: "buy",
    price: "$15,571",
    type: "2 + 1",
    nameproject: 'Venus Towers',
    number: 'B1-14',
    place: 'Erbil - hawlery nwe',
    area: "105 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-182",
    name:"apartment for sale majidi view 182 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"3 + 1",
    nameproject:"Majidi View",
    number:"6",
    area:"182 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },
  {
    id: 23,
    cover: "../images/property/skyview/dsf.jpg",
    url: "Apartment-for-Sale-Sky-View-B2-10-Floor",
    name: "Apartment for Sale Sky View B2 10 Floor",
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$23,120",
    type: "1 + 1",
    nameproject: 'Sky View',
    number: 'B2-10',
    place: 'Erbil - Newroz',
    area: "80 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 24,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-B1-21-Floor",
    name: "Apartment for Sale Venus Towers B1 21 Floor",
    location: "Erbil",
    category: "buy",
    price: "$17,054",
    type: "2 + 1",
    nameproject: 'Venus Towers',
    number: 'B1-21',
    place: 'Erbil - hawlery nwe',
    area: "115 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-D-11",
    name: "Apartment for Sale North Holland D 11",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'D-11',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-185",
    name:"apartment for sale majidi view 185 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"3 + 1",
    nameproject:"Majidi View",
    number:"7",
    area:"185 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },

  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-B-10-96",
    name: "Apartment for Sale North Holland B 10",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,893",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'B-10',
    place: 'Erbil - Dream city',
    area: "96 m",
    citys: 'Erbil',
    property: 'apartment'
  },

  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-B-13-90",
    name: "Apartment for Sale North Holland B 13",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'B-13',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-F-7-90",
    name: "Apartment for Sale North Holland F 7",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'F-7',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-F-8-90",
    name: "Apartment for Sale North Holland F 8",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'F-8',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-D-12-90",
    name: "Apartment for Sale North Holland D 12",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'D-12',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-B-10",
    name: "Apartment for Sale North Holland B 10",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$28,203",
    type: "3 + 1",
    nameproject: 'North Holland',
    number: 'B-10',
    place: 'Erbil - Dream city',
    area: "210 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-396",
    name:"apartment for sale majidi view 396 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"5 + 1",
    nameproject:"Majidi View",
    number:"8",
    area:"396 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },

  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-B-5-floor",
    name: "Apartment for Sale North Holland B 5",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'B-5',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 44,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-B-8",
    name: "Apartment for Sale North Holland B 8",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'North Holland',
    number: 'B-8',
    place: 'Erbil - Dream city',
    area: "90 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 44,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-C-6",
    name: "Apartment for Sale North Holland C 6",
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$18,802",
    type: "2 + 1",
    nameproject: 'North Holland',
    number: 'C-6',
    place: 'Erbil - Dream city',
    area: "140 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 25,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A2-17-Floor",
    name: "Apartment for Sale Venus Towers A2 17 Floor",
    location: "Erbil",
    category: "buy",
    price: "$11,276",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A2-17',
    place: 'Erbil - hawlery nwe',
    area: "67 m",
    citys: 'Erbil',
    property: 'apartment'
  },
  {
    id: 26,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-B3-13-Floor",
    name: "Apartment for Sale Venus Towers B3 13 Floor",
    location: "Erbil",
    category: "buy",
    price: "$16,096",
    type: "2 + 1",
    nameproject: 'Venus Towers',
    number: 'B3-13',
    place: 'Erbil - hawlery nwe',
    area: "105 m",
    citys: 'Erbil',
    property: 'apartment'

  },
  
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-435",
    name:"apartment for sale majidi view 435 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    type:"5 + 1",
    nameproject:"Majidi View",
    number:"9",
    area:"435 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },

  {
    id: 27,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-A3-6-Floor",
    name: "Apartment for Sale Venus Towers A3 6 Floor",
    location: "Erbil",
    category: "buy",
    price: "$14,442",
    type: "1 + 1",
    nameproject: 'Venus Towers',
    number: 'A3-6',
    place: 'Erbil - hawlery nwe',
    area: "81 m",
    citys: 'Erbil'
    ,
    property: 'apartment'
  },

  {
    id: 41,
    cover: "../images/property/qaywan/qaywan_blog.jpg",
    url: "Apartment-for-Sale-QAIWAN-MIRADOR-C-19-floor",
    name: "Apartment for Sale QAIWAN MIRADOR C 19",
    location: "Erbil - Masif Road Pirmam",
    category: "buy",
    price: "$28,305",
    type: "1 + 4",
    nameproject: 'Qaiwan mirador',
    number: 'C-19',
    place: 'Erbil - Dream city',
    area: "201 m",
    property: 'apartment',
    citys: 'Erbil'
  },
  {
    cover:"../images/property/majidi/majidism.jpg",
    url:"apartment-for-sale-majidi-view-150",
    name:"apartment for sale majidi view 150 meter",
    location:"Erbil",
    category:"Buy",
    price:"Call us",
    number: '5',
    type:"3 + 1",
    nameproject:"Majidi View",
    number:"5",
    area:"150 m",
    citys:"Erbil",
    property:"Apartment",
    place:"Erbil - koya road",
    id:"646dd7a74cce70fbd1e5d8e7",
  },

]



export const list = [
  {
    id: 1,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'Apartment-for-Sale-Sky-View-B1-30-Floor',
    name: "Sky View B1 30 Floor",
    nameproject: 'Sky View',
    number: 'B1-30',
    location: "Lalav Sky View, Erbil",
    category: "buy",
    price: "$22,620",
    type: "1 + 1",
    area: "78 m",
    place: 'Erbil - Nawroz'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "Apartment-for-Sale-North-Holland-B-5-floor",
    name: "Apartment for Sale North Holland B 5",
    nameproject: 'North Holland',
    number: 'B-5',
    place: 'Erbil - Dream city',
    location: "Erbil - Mosul Road, opposite of Dream City",
    category: "buy",
    price: "$12,087",
    type: "1 + 1",
    area: "90 m"
  },
  
  {
    id: 43,
    cover: "../images/property/qaywan/qaywan_blog.jpg",
    url: "Apartment-for-Sale-QAIWAN-MIRADOR-B-6-201",
    name: "Apartment for Sale QAIWAN MIRADOR B 6",
    location: "Erbil - Masif Road Pirmam",
    nameproject: 'Qaiwan Mirador',
    number: 'B-6',
    place: 'Erbil - Shaways',
    category: "buy",
    price: "$28,305",
    type: "1 + 4",
    area: "201.6 m"
  },
  {
    id: 24,
    cover: "../images/property/venus/asdfaf.jpg",
    url: "Apartment-for-Sale-Venus-Towers-B1-21-Floor",
    name: "Apartment for Sale Venus Towers B1 21 Floor",
    nameproject: 'Venus Towers',
    number: 'B1-21',
    place: 'Erbil - New Haweler',
    location: "Erbil",
    category: "buy",
    price: "$17,054",
    type: "2 + 1",
    area: "115 m"
  },

]

export const arhousesData = [
  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-سكاي-فيو-٣٠-ب١',
    name: 'B1 30 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '1',
    bathrooms: '1',
    area: '78 متر',
    year: '2023',
    price: '22,620',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-داماك-باي٢-٧٢',
    name: "شقة للبیع داماك بای ٢ ٧٢ ",
    nameproperty: 'سكاي فيو ',
    build: '2',
    Allbuild: '48',
    image: '/images/property/bay2/baybg.jpg',
    imageLg: '/images/property/bay2/baybg.jpg',
    country: 'دبي',
    address: 'دبي - هاریر',
    bedrooms: '1',
    bathrooms: '1',
    area: '72 متر',
    year: '2027',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/mriam.jpg',
      name: 'مریم موفق',
      phone: "tel:+9647505622006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  
  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-داماك-باي٢-١٠٨',
    name: "شقة للبیع داماك بای ٢ ١٠٨ ",
    nameproperty: 'سكاي فيو ',
    build: '2',
    Allbuild: '48',
    image: '/images/property/bay2/baybg.jpg',
    imageLg: '/images/property/bay2/baybg.jpg',
    country: 'دبي',
    address: 'دبي - هاریر',
    bedrooms: '2',
    bathrooms: '1',
    area: '108 متر',
    year: '2027',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/mriam.jpg',
      name: 'مریم موفق',
      phone: "tel:+9647505622006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  
  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-داماك-باي٢-١٤٤',
    name: "شقة للبیع داماك بای ٢ ١٤٤ ",
    nameproperty: 'سكاي فيو ',
    build: '2',
    Allbuild: '48',
    image: '/images/property/bay2/baybg.jpg',
    imageLg: '/images/property/bay2/baybg.jpg',
    country: 'دبي',
    address: 'دبي - هاریر',
    bedrooms: '3',
    bathrooms: '1',
    area: '144 متر',
    year: '2027',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/mriam.jpg',
      name: 'مریم موفق',
      phone: "tel:+9647505622006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  





  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-٨٣',
    name: 'شقة للبیع ماجدي فیو ٨٣',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '2',
    bathrooms: '1',
    area: '83 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },


  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-١٠٣',
    name: 'شقة للبیع ماجدي فیو ١٠٣',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '2',
    bathrooms: '1',
    area: '103 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-١٠٨',
    name: 'شقة للبیع ماجدي فیو ١٠٨',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '2',
    bathrooms: '1',
    area: '108 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-١٤٧',
    name: 'شقة للبیع ماجدي فیو ١٤٧',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '2',
    bathrooms: '1',
    area: '147 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },


  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-١٥٠',
    name: 'شقة للبیع ماجدي فیو ١٥٠',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '3',
    bathrooms: '1',
    area: '150 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-١٨٢',
    name: 'شقة للبیع ماجدي فیو ١٨٢',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '3',
    bathrooms: '1',
    area: '182 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },




  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-١٨٥',
    name: 'شقة للبیع ماجدي فیو ١٨٥',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '3',
    bathrooms: '1',
    area: '185 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },




  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-٣٩٦',
    name: 'شقة للبیع ماجدي فیو ٣٩٦',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '5',
    bathrooms: '1',
    area: '396 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-٤٣٥',
    name: 'شقة للبیع ماجدي فیو ٤٣٥',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '5',
    bathrooms: '1',
    area: '435 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },


  {
    id: 1,
    type: 'شقة',
    url: 'شقة-للبیع-ماجدي-فیو-٥١٨',
    name: 'شقة للبیع ماجدي فیو ٥١٨',
    nameproperty: 'ماجدي فیو ',
    build: '11',
    Allbuild: '24',
    image: '/images/property/majidi/majidibg.jpg',
    imageLg: '/images/property/majidi/majidibg.jpg',
    country: 'عراق',
    address: 'اربيل - طريق کویة',
    bedrooms: '5',
    bathrooms: '1',
    area: '518 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 4,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-١٢-ب١',
    name: 'B1 12 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '6',
    bathrooms: '3',
    area: '80 متر',
    year: '2023',
    price: '23,920',
    agent: {
      image: '/images/customer/mahmood.webp',
      name: 'محمود عبدالله',
      phone: "tel:+9647501882006",
      email: 'mahmood@zest-property.com'
    },
  },
  {
    id: 56,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٦-ئەی-٢١٠",
    name: "A 6 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '210 م',
    year: '2023',
    price: '28,203',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٠-دي-١٤٠",
    name: "D 10 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '3',
    bathrooms: '1',
    area: '140 م',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 56,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٤-ف-١٤٠",
    name: "F 14 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '3',
    bathrooms: '1',
    area: '140 م',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٣-دي-١٤٠",
    name: "D 13 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '3',
    bathrooms: '1',
    area: '140 م',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 56,
    type: 'شقة',
    url: "منزل-للبیع-سکاي-لاند-نوع-١",
    name: "منزل للبیع سکاي لاند نوع ١",
    nameproperty: 'سكاي لاند',
    build: '150',
    Allbuild: '1',
    image: '/images/property/skyland/skylandbg.jpg',
    imageLg: '/images/property/skyland/skylandbg.jpg',
    country: 'Iraq',
    address: 'کرکوك - مكتب المرور',
    bedrooms: '2',
    bathrooms: '1',
    area: '200 م',
    year: '2023',
    price: '61,000',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: "akam.a@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'شقة',
    url: "منزل-للبیع-سکاي-لاند-نوع-٢",
    name: "منزل للبیع سکاي لاند نوع ٢",
    nameproperty: 'سكاي لاند',
    build: '150',
    Allbuild: '2',
    image: '/images/property/skyland/skylandbg.jpg',
    imageLg: '/images/property/skyland/skylandbg.jpg',
    country: 'عراق',
    address: 'کرکوك - مكتب المرور',
    bedrooms: '4',
    bathrooms: '1',
    area: '211 م',
    year: '2023',
    price: '91,000',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: "akam.a@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'شقة',
    url: "منزل-للبیع-سکاي-لاند-نوع-ڤاپ",
    name: "منزل للبیع سکاي لاند نوع ڤاپ",
    nameproperty: 'سكاي لاند',
    build: '150',
    Allbuild: '2',
    image: '/images/property/skyland/skylandbg.jpg',
    imageLg: '/images/property/skyland/skylandbg.jpg',
    country: 'عراق',
    address: 'کرکوك - مكتب المرور',
    bedrooms: '4',
    bathrooms: '1',
    area: '279 م',
    year: '2023',
    price: '110,000',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: "akam.a@zest-property.com"
    },
  },
  {
    id: 5,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٢٢-ئەی١',
    name: 'A1 22 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '2',
    bathrooms: '1',
    area: '105 متر',
    year: '2023',
    price: '16,096',
    agent: {
      image: '/images/customer/muhammadmajed.webp',
      name: 'محمد مجيد',
      phone: "tel:+9647502422006",
      email: "mohammad.m@zest-property.com",
    },
  },
  {
    id: 6,
    type: 'شقة',
    url: 'شقة-للبیع-الود-١',
    name: 'شقة للبیع الود ',
    nameproperty: 'الود ',
    build: '19',
    Allbuild: '15',
    image: '/images/property/alwedd/3.jpg',
    imageLg: '/images/property/alwedd/3.jpg',
    country: 'عراق',
    address: 'بغداد - الأمریة',
    bedrooms: '3',
    bathrooms: '1',
    area: '180 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'مركز الاتصال',
      phone: "tel:+9647702302006",
      email: "akam.a@zest-property.com",
    },
  },

  {
    id: 6,
    type: 'شقة',
    url:'شقة-للبیع-الخلود',
    name: 'شقة للبیع الخلود ',
    nameproperty: 'الخلود ',
    build: '4',
    Allbuild: '16',
    image: '/images/property/klood/khloodbg.jpg',
    imageLg: '/images/property/klood/khloodbg.jpg',
    country: 'عراق',
    address: 'بغداد - حي العدل - مقابل جامع',
    bedrooms: '3',
    bathrooms: '1',
    area: '193 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'مركز الاتصال',
      phone: "tel:+964776212006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 6,
    type: 'شقة',
    url: 'شقة-للبیع-الود-٢',
    name: 'شقة للبیع الود ',
    nameproperty: 'الود ',
    build: '19',
    Allbuild: '15',
    image: '/images/property/alwedd/3.jpg',
    imageLg: '/images/property/alwedd/3.jpg',
    country: 'عراق',
    address: 'بغداد - الأمریة',
    bedrooms: '4',
    bathrooms: '1',
    area: '200 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'مركز الاتصال',
      phone: "tel:+9647702302006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 6,
    type: 'فلا',
    url: 'فلا-للبیع-الود',
    name: 'فلا للبیع الود ',
    nameproperty: 'الود ',
    build: '19',
    Allbuild: '15',
    image: '/images/property/alwedd/3.jpg',
    imageLg: '/images/property/alwedd/3.jpg',
    country: 'عراق',
    address: 'بغداد - الأمریة',
    bedrooms: '4',
    bathrooms: '1',
    area: '200 متر',
    year: '2023',
    price: 'اتصل بنا',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'مركز الاتصال',
      phone: "tel:+9647702302006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 6,
    type: 'شقة',
    url: 'شقة-للبیع-نوفا-تاورز-١٤-ئەی٢',
    name: 'A2 14 شقة للبیع نوفا تاورز',
    nameproperty: 'نوفا تاورز ',
    build: '5',
    Allbuild: '22',
    image: '/images/property/nova/ewr1.jpg',
    imageLg: '/images/property/nova/ewr1.jpg',
    country: 'عراق',
    address: 'اربيل - طريق بحركة طريق',
    bedrooms: '1',
    bathrooms: '1',
    area: '81 متر',
    year: '2023',
    price: '37,000',
    agent: {
      image: '/images/customer/shaho.webp',
      name: 'شاهو دياري',
      phone: "tel:+9647509632006",
      email: "shahoo.d@zest-property.com",
    },
  },
  {
    id: 9,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٢٥-ئەی١',
    name: 'A1 25 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '72 متر',
    year: '2023',
    price: '10,317',
    agent: {
      image: '/images/customer/AkamAli.webp',
      name: 'اكام علي',
      phone: "tel:+9647501762006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 10,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٨-ئەی١',
    name: 'A1 8 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '72 متر',
    year: '2022',
    price: '11,397',
    agent: {
      image: '/images/customer/Aya.Alsaabb.webp',
      name: 'ايه الصعبb',
      phone: "tel:+9647506322006",
      email: "aya.s@zest-property.com"
    },
  },
  {
    id: 11,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٣١-ئەی٣',
    name: 'A3 31 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '2',
    bathrooms: '1',
    area: '120 متر',
    year: '2022',
    price: '35,856',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 13,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٣٧-ب٣',
    name: 'B3 37 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '2',
    bathrooms: '1',
    area: '120 متر',
    year: '2022',
    price: '38,256',
    agent: {
      image: '/images/customer/mahmood.webp',
      name: 'محمود عبدالله',
      phone: "tel:+9647501882006",
      email: 'mahmood@zest-property.com'
    },
  },
  {
    id: 14,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-١٤-ئەی٢',
    name: 'A2 14 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '76 متر',
    year: '2022',
    price: '13,550',
    agent: {
      image: '/images/customer/muhammadmajed.webp',
      name: 'محمد مجيد',
      phone: "tel:+9647502422006",
      email: "mohammad.m@zest-property.com",
    },
  },
  {
    id: 15,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٢٢-ب٣',
    name: 'B3 22 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '2',
    bathrooms: '1',
    area: '120 متر',
    year: '2022',
    price: '43,561',
    agent: {
      image: '/images/customer/aliagha.webp',
      name: 'علي آغا',
      phone: "tel:+9647505912006",
      email: 'ali.a@zest-property.com',
    },
  },
  {
    id: 16,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-١٣-ئەی١',
    name: 'A1 13 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '2',
    bathrooms: '1',
    area: '115 متر',
    year: '2022',
    price: '15,904',
    agent: {
      image: '/images/customer/muhammedrasul.webp',
      name: 'محمد رسول',
      phone: "tel:+9647506912006",
      email: "M.Rasul@zest-property.com",
    },
  },
  {
    id: 17,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٧-ب٣',
    name: 'B3 7 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 متر',
    year: '2022',
    price: '23,120',
    agent: {
      image: '/images/customer/Aya.Asaab.webp',
      name: 'ايه الصعب',
      phone: "tel:+9647506322006",
      email: "aya.s@zest-property.com"
    },
  },
  {
    id: 18,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٦-ئەی٢',
    name: 'A2 6 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '67 متر',
    year: '2022',
    price: '12,951',
    agent: {
      image: '/images/customer/rasty.webp',
      name: 'راستي كيفي',
      phone: "tel:+9647507342006",
      email: "Rasty.kayfi@zest-property.com"
    }
  },
  {
    id: 19,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٧-ئەی١',
    name: 'A1 7 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 متر',
    year: '2022',
    price: '34,400',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    }
  },
  {
    id: 20,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٥-ئەی٢',
    name: 'A2 5 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '67 متر',
    year: '2022',
    price: '12,951',
    agent: {
      image: '/images/customer/muhammadmajed.webp',
      name: 'محمد مجيد',
      phone: "tel:+9647502422006",
      email: "mohammad.m@zest-property.com",
    }
  },
  {
    id: 21,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٦-ب٢',
    name: 'B2 6 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 متر',
    year: '2022',
    price: '23,120',
    agent: {
      image: '/images/customer/aliagha.webp',
      name: 'علي آغا',
      phone: "tel:+9647505912006",
      email: 'ali.a@zest-property.com',
    }
  },
  {
    id: 22,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-١٤-ب١',
    name: 'B1 14 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '2',
    bathrooms: '1',
    area: '105 متر',
    year: '2022',
    price: '15,571',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    }
  },
  {
    id: 23,
    type: 'شقة',
    url: 'شقة-للبیع-لالاف-سكاي-فيو-١٠-ب٢',
    name: 'B2 10 شقة للبیع لالاف سكاي فيو',
    nameproperty: 'سكاي فيو ',
    build: '6',
    Allbuild: '39',
    image: '/images/property/skyview/dsf1.jpg',
    imageLg: '/images/property/skyview/dsf1.jpg',
    country: 'عراق',
    address: 'اربيل - امام بارك سامي عبد الرحمن',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 متر',
    year: '2022',
    price: '23,120',
    agent: {
      image: '/images/customer/rasty.webp',
      name: 'راستي كيفي',
      phone: "tel:+9647507342006",
      email: "Rasty.kayfi@zest-property.com"
    }
  },
  {
    id: 24,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٢١-ب١',
    name: 'B1 21 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '2',
    bathrooms: '1',
    area: '115 متر',
    year: '2022',
    price: '17,054',
    agent: {
      image: '/images/customer/mahmood.webp',
      name: 'محمد مجيد',
      phone: "tel:+9647501882006",
      email: "mohammad.m@zest-property.com",
    }
  },
  {
    id: 25,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-١٧-ئەی٢',
    name: 'A2 17 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '67 متر',
    year: '2022',
    price: '11,276',
    agent: {
      image: '/images/customer/aliagha.webp',
      name: 'علي آغا',
      phone: "tel:+9647505912006",
      email: 'ali.a@zest-property.com',
    }
  },
  {
    id: 26,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-١٣-ب٣',
    name: 'B3 13 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '2',
    bathrooms: '1',
    area: '105 متر',
    year: '2022',
    price: '16,096',
    agent: {
      image: '/images/customer/Aya.Alsaab.webp',
      name: 'ايه الصعب',
      phone: "tel:+9647506322006",
      email: "aya.s@zest-property.com"
    }
  },
  {
    id: 27,
    type: 'شقة',
    url: 'شقة-للبیع-فینوس-تاوەر-٦-ئەی٣',
    name: 'A3 6 شقة للبیع فینوس تاوەر',
    nameproperty: 'فینوس تاوەر ',
    build: '6',
    Allbuild: '30',
    image: '/images/property/venus/asdfaf1.jpg',
    imageLg: '/images/property/venus/asdfaf1.jpg',
    country: 'عراق',
    address: 'اربيل - شارع 120 - امام هوليري نوي',
    bedrooms: '1',
    bathrooms: '1',
    area: '81 متر',
    year: '2022',
    price: '14,442',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'كيوان كيفي',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    }
  },


  {
    id: 46,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٩-ئەی-٦",
    name: "A 9 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 46,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٥-ئەی",
    name: "A 5 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 46,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٠-بي-٩٦",
    name: "B 10 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '96 m²',
    year: '2023',
    price: '12,893',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 46,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٠-بي",
    name: "B 10 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '3',
    bathrooms: '1',
    area: '210 m²',
    year: '2023',
    price: '28,203',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },


  {
    id: 44,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٨-بی",
    name: "B 8 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 45,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٥-بی",
    name: "B 5 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٥-بي",
    name: "B 5 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٣-دي",
    name: "D 13 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٧-في",
    name: "F 7 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٨-في",
    name: "F 8 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٢-دي",
    name: "D 12 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٥-دي",
    name: "شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١٣-ئەي",
    name: "شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },


  {
    id: 40,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٧-فی",
    name: "F 7 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 40,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-١١-دي",
    name: "D 11 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2023',
    price: '12,087',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 40,
    type: 'شقة',
    url: "شقة-للبیع-نورث-هولند-٦-سي",
    name: "C 6 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City',
    bedrooms: '2',
    bathrooms: '1',
    area: '140 m²',
    year: '2023',
    price: '18,802',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 41,
    type: 'شقة',
    url: "شقة-للبیع-قیوان-میرادور-١٩-سي",
    name: "C 19 شقة للبیع نورث هولند",
    nameproperty: 'نورث هولند',
    build: '6',
    Allbuild: '20',
    image: '/images/property/north/north_holland_hd.jpg',
    imageLg: '/images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Masif Road Pirmam',
    bedrooms: '4',
    bathrooms: '1',
    area: '201.6 m²',
    year: '2022',
    price: '28,305',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 43,
    type: 'شقة',
    url: "شقة-للبیع-قیوان-میرادور-٦-بي-٢٠١",
    name: "B 6 شقة للبیع قیوان میرادور",
    nameproperty: 'قیوان میرادور',
    build: '9',
    Allbuild: '29',
    image: '/images/property/qaywan/qaywan_hd.jpg',
    imageLg: '/images/property/qaywan/qaywan_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Masif Road Pirmam',
    bedrooms: '4',
    bathrooms: '1',
    area: '201.6 m²',
    year: '2022',
    price: '28,305',
    agent: {
      image: '/images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

]



export const arlistAll = [
  {
    id: 1,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-سكاي-فيو-٣٠-ب١',
    name: "B1 30 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    nameproject: 'سكاي فيو',
    number: 'B1-30',
    place: 'إربیل - نوروز',
    category: "للبيع",
    price: "$22,620",
    type: "1 + 1",
    area: "78 م",
    citys: 'اربيل',
    property: 'شقة'
  },


  {
    id: 1,
    cover:"../images/property/bay2/baysm.jpg",
    url: 'شقة-للبیع-داماك-باي٢-٧٢',
    name: "شقة للبیع داماك باي٢",
    location: "دبي",
    nameproject: 'داماك باي ٢',
    number: '1',
    place: 'دبي - هاریر',
    category: "للبيع",
    price: "اتصل بنا",
    type: "1 + 1",
    area: "72 م",
    citys: 'دبي',
    property: 'شقة'
  },


  {
    id: 4,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-١٢-ب١',
    name: "B1 12 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    nameproject: 'سكاي فيو',
    number: 'B1-12',
    place: 'إربیل - نوروز',
    category: "للبيع",
    price: "$23,920",
    type: "1 + 1",
    area: "80 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 18,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٦-ئەی٢',
    name: "A2 6 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$12,951",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A2-6',
    place: 'إربیل - هیولیري نوي',
    area: "67 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 56,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٦-ئەی-٢١٠",
    name: "A 6 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$28,203",
    type: "1 + 3",
    nameproject: 'نورث هولند',
    number: 'A-6',
    place: 'إربیل - دریم ستي',
    area: "210 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 56,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٠-دي-١٤٠",
    name: "D 10 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "اتصل بنا",
    type: "1 + 3",
    nameproject: 'نورث هولند',
    number: 'D-10',
    place: 'إربیل - دریم ستي',
    area: "140 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 56,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٣-دي-١٤٠",
    name: "D 13 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "اتصل بنا",
    type: "1 + 3",
    nameproject: 'نورث هولند',
    number: 'D-13',
    place: 'إربیل - دریم ستي',
    area: "140 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 56,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٤-ف-١٤٠",
    name: "F 14 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "اتصل بنا",
    type: "1 + 3",
    nameproject: 'نورث هولند',
    number: 'F-14',
    place: 'إربیل - دریم ستي',
    area: "140 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 5,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٢٢-ئەی١',
    name: "A1 22 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$16,096",
    type: "2 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A1-22',
    place: 'إربیل - هیولیري نوي',
    area: "105 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٥-بي",
    name: "B 5 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'B-5',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٣-دي",
    name: "D 13 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'D-13',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٧-في",
    name: "F 7 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'F-7',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٨-في",
    name: "F 8 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'F-8',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٢-دي",
    name: "D 12 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'D-12',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 1,
    cover:"../images/property/bay2/baysm.jpg",
    url: 'شقة-للبیع-داماك-باي٢-١٤٤',
    name: "شقة للبیع داماك باي٢",
    location: "دبي",
    nameproject: 'داماك باي ٢',
    number: '3',
    place: 'دبي - هاریر',
    category: "للبيع",
    price: "اتصل بنا",
    type: "3 + 1",
    area: "144 م",
    citys: 'دبي',
    property: 'شقة'
  },


  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٣-ئەي",
    name: "شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'A-13',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-٥١٨',
    name: "شقة للبیع ماجدي فیو ٥١٨",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '10',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "5 + 1",
    area: "518 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 6,
    cover: "../images/property/nova/ewr.jpg",
    url: 'شقة-للبیع-نوفا-تاورز-١٤-ئەی٢',
    name: "A2 14 شقة للبیع نوفا تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$37,000",
    type: "1 + 1",
    nameproject: 'نوفا تاوەر',
    number: 'A2-14',
    place: 'إربیل - کنجان ستي',
    area: "81 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 6,
    cover: "../images/property/alwedd/2.jpg",
    url: "شقة-للبیع-الود-١",
    name: "شقة للبیع الود ١",
    location: "بغداد",
    category: "للبيع",
    price: "اتصل بنا",
    type: "3 + 1",
    nameproject: 'الود',
    number: '1',
    place: 'بغداد - الأمریة',
    area: "180 م",
    citys: 'بغداد',
    property: 'شقة'
  },
  {
    id: 9,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٢٥-ئەی١',
    name: "A1 25 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$10,317",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A1-25',
    place: 'إربیل - هیولیري نوي',
    area: "72 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 10,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٨-ئەی١',
    name: "A1 8 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$11,397",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A1-8',
    place: 'إربیل - هیولیري نوي',
    area: "72 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 1,
    cover:"../images/property/bay2/baysm.jpg",
    url: 'شقة-للبیع-داماك-باي٢-١٠٨',
    name: "شقة للبیع داماك باي٢",
    location: "دبي",
    nameproject: 'داماك باي ٢',
    number: '2',
    place: 'دبي - هاریر',
    category: "للبيع",
    price: "اتصل بنا",
    type: "2 + 1",
    area: "108 م",
    citys: 'دبي',
    property: 'شقة'
  },
  {
    id: 11,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٣١-ئەی٣',
    name: "A3 31 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$35,856",
    type: "2 + 1",
    nameproject: 'سكاي فيو',
    number: 'A3-31',
    place: 'إربیل - نوروز',
    area: "120 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  



  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-١٠٣',
    name: "شقة للبیع ماجدي فیو ١٠٣",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '2',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "2 + 1",
    area: "103 م",
    citys: 'اربيل',
    property: 'شقة'
  },


  {
    id: 11,
    cover: "../images/property/skyland/skylandsm.jpg",
    url: 'منزل-للبیع-سکاي-لاند-نوع-١',
    name: "منزل للبیع سکاي لاند نوع ١",
    location: "مكتب المرور, کرکوك",
    category: "للبيع",
    price: "$69,000",
    type: "منزل",
    nameproject: 'سكاي لاند',
    number: '1',
    place: 'کرکوك - مكتب المرور',
    area: "200 م",
    citys: 'کرکوك',
    property: 'منزل'
  },
  {
    id: 13,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٣٧-ب٣',
    name: "B3 37 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$38,256",
    type: "2 + 1",
    nameproject: 'سكاي فيو',
    number: 'B3-37',
    place: 'إربیل - نوروز',
    area: "120 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 6,
    cover: "../images/property/alwedd/2.jpg",
    url: "شقة-للبیع-الود-٢",
    name: "شقة للبیع الود ٢",
    location: "بغداد",
    category: "للبيع",
    price: "اتصل بنا",
    type: "4 + 1",
    nameproject: 'الود',
    number: '1',
    place: 'بغداد - الأمریة',
    area: "200 م",
    citys: 'بغداد',
    property: 'شقة'
  },
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-٤٣٥',
    name: "شقة للبیع ماجدي فیو ٤٣٥",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '9',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "5 + 1",
    area: "495 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 38,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٥-ئەی",
    name: "A 5 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'A-5',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 6,
    cover: "../images/property/klood/khloodsm.jpg",
    url: "شقة-للبیع-الخلود",
    name: "شقة للبیع الخلود",
    location: "بغداد",
    category: "للبيع",
    price: "اتصل بنا",
    type: "3 + 1",
    nameproject: 'الخلود',
    number: '1',
    place: 'بغداد - حي العدل - مقابل جامع',
    area: "193 م",
    citys: 'بغداد',
    property: 'شقة'
  },
  {
    id: 38,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٠-بي-٩٦",
    name: "B 10 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,893",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'B-10',
    place: 'إربیل - دریم ستي',
    area: "96 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  

  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-١٨٢',
    name: "شقة للبیع ماجدي فیو ١٨٢",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '6',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "3 + 1",
    area: "182 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 11,
    cover: "../images/property/skyland/skylandsm.jpg",
    url: 'منزل-للبیع-سکاي-لاند-نوع-٢',
    name: "منزل للبیع سکاي لاند نوع ٢",
    location: "مكتب المرور, کرکوك",
    category: "للبيع",
    price: "$91,000",
    type: "منزل",
    nameproject: 'سكاي لاند',
    number: '2',
    place: 'کرکوك - مكتب المرور',
    area: "211 م",
    citys: 'کرکوك',
    property: 'منزل'
  },

  {
    id: 38,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١٠-بي",
    name: "B 10 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$28,2",
    type: "3 + 1",
    nameproject: 'نورث هولند',
    number: 'B-10',
    place: 'إربیل - دریم ستي',
    area: "210 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-١٨٥',
    name: "شقة للبیع ماجدي فیو ١٨٥",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '7',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "3 + 1",
    area: "185 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 6,
    cover: "../images/property/alwedd/2.jpg",
    url: "فلا-للبیع-الود",
    name: "فلا للبیع الود",
    location: "بغداد",
    category: "للبيع",
    price: "اتصل بنا",
    type: "منزل",
    nameproject: 'الود',
    number: '1',
    place: 'بغداد - الأمریة',
    area: "300 م",
    citys: 'بغداد',
    property: 'فلا'
  },
  {
    id: 14,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-١٤-ئەی٢',
    name: "A2 14 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$13,550",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A2-14',
    place: 'إربیل - هیولیري نوي',
    area: "76 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-١٥٠',
    name: "شقة للبیع ماجدي فیو ١٥٠",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '5',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "3 + 1",
    area: "150 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 11,
    cover: "../images/property/skyland/skylandsm.jpg",
    url: 'منزل-للبیع-سکاي-لاند-نوع-ڤاپ',
    name: "منزل للبیع سکاي لاند نوع ڤاپ",
    location: "مكتب المرور, کرکوك",
    category: "للبيع",
    price: "$110,000",
    type: "منزل",
    nameproject: 'سكاي لاند',
    number: '2',
    place: 'کرکوك - مكتب المرور',
    area: "270 م",
    citys: 'کرکوك',
    property: 'منزل'
  },
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-٨٣',
    name: "شقة للبیع ماجدي فیو ٨٣",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '1',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "2 + 1",
    area: "83 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 15,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٢٢-ب٣',
    name: "B3 22 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$38,256",
    type: "2 + 1",
    nameproject: 'سكاي فيو',
    number: 'B3-22',
    place: 'إربیل - نوروز',
    area: "120 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 16,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-١٣-ئەی١',
    name: "A1 13 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$15,904",
    type: "2 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A1-13',
    place: 'إربیل - هیولیري نوي',
    area: "115 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-١٠٨',
    name: "شقة للبیع ماجدي فیو ١٠٨",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '3',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "2 + 1",
    area: "108 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 43,
    cover: "../images/property/qaywan/qaywan_blog.jpg",
    url: "شقة-للبیع-قیوان-میرادور-٦-بي-٢٠١",
    name: "B 6 شقة للبیع قیوان میرادور",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$28,305",
    type: "1 + 4",
    nameproject: 'قیوان میرادور',
    number: 'B-6',
    place: 'إربیل - دریم ستي',
    area: "201.6 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 17,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٧-ب٣',
    name: "B3 7 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$23,120",
    type: "1 + 1",
    nameproject: 'سكاي فيو',
    number: 'B3-7',
    place: 'إربیل - نوروز',
    area: "80 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-١٤٧',
    name: "شقة للبیع ماجدي فیو ١٤٧",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '4',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "2 + 1",
    area: "147 م",
    citys: 'اربيل',
    property: 'شقة'
  },




  {
    id: 19,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٧-ئەی١',
    name: "A1 7 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$34,400",
    type: "1 + 1",
    nameproject: 'سكاي فيو',
    number: 'A1-7',
    place: 'إربیل - نوروز',
    area: "80 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 20,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٥-ئەی٢',
    name: "A2 5 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$12,951",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A2-5',
    place: 'إربیل - هیولیري نوي',
    area: "67 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 21,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-٦-ب٢',
    name: "B2 6 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$23,120",
    type: "1 + 1",
    nameproject: 'سكاي فيو',
    number: 'B2-6',
    place: 'إربیل - نوروز',
    area: "80 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 22,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-١٤-ب١',
    name: " B1 14 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$15,571",
    type: "2 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'B1-14',
    place: 'إربیل - هیولیري نوي',
    area: "105 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 23,
    cover: "../images/property/skyview/dsf.jpg",
    url: 'شقة-للبیع-لالاف-سكاي-فيو-١٠-ب٢',
    name: "B2 10 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    category: "للبيع",
    price: "$23,120",
    type: "1 + 1",
    nameproject: 'سكاي فيو',
    number: 'B2-10',
    place: 'إربیل - نوروز',
    area: "80 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 33,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٧-فی",
    name: "F 7 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'F-7',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  
  {
    id: 1,
    cover: "../images/property/majidi/majidism.jpg",
    url: 'شقة-للبیع-ماجدي-فیو-٣٩٦',
    name: "شقة للبیع ماجدي فیو ٣٩٦",
    location: "اربيل",
    nameproject: 'ماجدي فیو',
    number: '8',
    place: 'إربیل - طريق کویة',
    category: "للبيع",
    price: "اتصل بنا",
    type: "5 + 1",
    area: "396 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 33,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-١١-دي",
    name: "D 11 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'D-11',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 33,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٦-سي",
    name: "C 6 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,802",
    type: "2 + 1",
    nameproject: 'نورث هولند',
    number: 'C-6',
    place: 'إربیل - دریم ستي',
    area: "140 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 33,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٥-دي",
    name: "شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'D-5',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 44,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٨-بی",
    name: "B 8 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'B-8',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 45,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٥-بی",
    name: "B 5 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    nameproject: 'نورث هولند',
    number: 'B-5',
    place: 'إربیل - دریم ستي',
    area: "90 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 24,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٢١-ب١',
    name: "B1 21 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$17,054",
    type: "2 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'B1-21',
    place: 'إربیل - هیولیري نوي',
    area: "115 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 25,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-١٧-ئەی٢',
    name: "A2 17 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$11,276",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A2-17',
    place: 'إربیل - هیولیري نوي',
    area: "67 م",
    citys: 'اربيل',
    property: 'شقة'
  },

  {
    id: 26,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-١٣-ب٣',
    name: "B3 13 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$16,096",
    type: "2 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'B3-13',
    place: 'إربیل - هیولیري نوي',
    area: "105 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 27,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٦-ئەی٣',
    name: "A3 6 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    category: "للبيع",
    price: "$14,442",
    type: "1 + 1",
    nameproject: 'فينوس تاوەر',
    number: 'A3-6',
    place: 'إربیل - هیولیري نوي',
    area: "81 م",
    citys: 'اربيل',
    property: 'شقة'
  },
]

export const arlist = [
  {
    id: 1,
    cover: "../images/property/skyview/dsf.jpg",
    url: "شقة-للبیع-سكاي-فيو-٣٠-ب١",
    name: "B1 30 شقة للبیع سكاي فيو",
    location: "لالاف سكاي فيو, اربيل",
    nameproject: 'سكاي فيو',
    number: 'B1-30',
    place: 'إربیل - نوروز',
    category: "للبيع",
    price: "$22,620",
    type: "1 + 1",
    area: "78 م"
  },
  {
    id: 39,
    cover: "../images/property/north/north_holland_blog.jpg",
    url: "شقة-للبیع-نورث-هولند-٥-بي",
    name: "B 5 شقة للبیع نورث هولند",
    location: "أربيل - شارع موسل",
    nameproject: 'نورث هولند',
    number: 'B-5',
    place: 'إربیل - دریم ستي',
    category: "للبيع",
    price: "$12,087",
    type: "1 + 1",
    area: "90 م"
  },

  {
    id: 43,
    cover: "../images/property/qaywan/qaywan_blog.jpg",
    url: "شقة-للبیع-قیوان-میرادور-٦-بي-٢٠١",
    name: "B 6 شقة للبیع قیوان میرادور",
    location: "أربيل - شارع موسل",
    nameproject: 'قیوان میرادور',
    number: 'B-6',
    place: 'إربیل - دریم ستي',
    category: "للبيع",
    price: "$28,305",
    type: "1 + 4",
    area: "201.6 م",
    citys: 'اربيل',
    property: 'شقة'
  },
  {
    id: 27,
    cover: "../images/property/venus/asdfaf.jpg",
    url: 'شقة-للبیع-فینوس-تاوەر-٦-ئەی٣',
    name: "A3 6 شقة للبیع فينوس تاوەر",
    location: "اربيل",
    nameproject: 'فينوس تاوەر',
    number: 'A3 - 6',
    place: 'إربیل - هیولیري نوي',
    category: "للبيع",
    price: "$14,442",
    type: "1 + 1",
    area: "81 م"
  },
]


export const housesData = [
  {
    id: 1,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Sky-View-B1-30-Floor',
    name: 'Apartment for Sale Sky View B1 30 Floor',
    nameproperty: 'sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '1',
    bathrooms: '1',
    area: '78 meter',
    year: '2023',
    price: '22,620',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



    {
      id: 1,
      type: 'Apartment',
      url: 'apartment-for-sale-damac-bay2-72',
      name: 'apartment for sale damac bay2 72',
      nameproperty: 'Damac bay2',
      build: '2',
      Allbuild: '48',
      image: '../images/property/bay2/baybg.jpg',
      imageLg: '../images/property/bay2/baybg.jpg',
      country: 'Dubai',
      address: 'Dubai - Harbour',
      bedrooms: '1',
      bathrooms: '1',
      area: '72 meter',
      year: '2027',
      price: 'Call us',
      agent: {
        image: '../images/customer/mriam.jpg',
        name: 'mriam mofaq',
        phone: "tel:+9647505622006",
        email: "kaiwankaify@zest-property.com"
      },
    },
  


    {
      id: 1,
      type: 'Apartment',
      url: 'apartment-for-sale-damac-bay2-108',
      name: 'apartment for sale damac bay2 108',
      nameproperty: 'Damac bay2',
      build: '2',
      Allbuild: '48',
      image: '../images/property/bay2/baybg.jpg',
      imageLg: '../images/property/bay2/baybg.jpg',
      country: 'Dubai',
      address: 'Dubai - Harbour',
      bedrooms: '2',
      bathrooms: '1',
      area: '108 meter',
      year: '2027',
      price: 'Call us',
      agent: {
        image: '../images/customer/mriam.jpg',
        name: 'mriam mofaq',
        phone: "tel:+9647505622006",
        email: "kaiwankaify@zest-property.com"
      },
    },
  


    {
      id: 1,
      type: 'Apartment',
      url: 'apartment-for-sale-damac-bay2-144',
      name: 'apartment for sale damac bay2 144',
      nameproperty: 'Damac bay2',
      build: '2',
      Allbuild: '48',
      image: '../images/property/bay2/baybg.jpg',
      imageLg: '../images/property/bay2/baybg.jpg',
      country: 'Dubai',
      address: 'Dubai - Harbour',
      bedrooms: '3',
      bathrooms: '1',
      area: '144 meter',
      year: '2027',
      price: 'Call us',
      agent: {
        image: '../images/customer/mriam.jpg',
        name: 'mriam mofaq',
        phone: "tel:+9647505622006",
        email: "kaiwankaify@zest-property.com"
      },
    },
  






  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-83",
    name:"apartment for sale majidi view 83 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '2',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"83 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild: '24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },




  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-103",
    name:"apartment for sale majidi view 103 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '2',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"103 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild: '24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



  
  
  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-108",
    name:"apartment for sale majidi view 108 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '2',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"108 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  
  
  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-147",
    name:"apartment for sale majidi view 147 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '2',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"147 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  
  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-150",
    name:"apartment for sale majidi view 150 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '3',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"150 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  
  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-182",
    name:"apartment for sale majidi view 182 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '3',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"182 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-185",
    name:"apartment for sale majidi view 185 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '3',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"185 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },





  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-396",
    name:"apartment for sale majidi view 396 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '5',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"396 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },



  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-435",
    name:"apartment for sale majidi view 435 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '5',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"435 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },


  
  {
    image:"../images/property/majidi/majidibg.jpg",
    imageLg:"../images/property/majidi/majidibg.jpg",
    url:"apartment-for-sale-majidi-view-518",
    name:"apartment for sale majidi view 518 meter",
    location:"Erbil",
    country: 'Iraq',
    category:"buy",
    price:"Call us",
    bedrooms: '5',
    bathrooms: '1',
    year: '2023',
    type:"Apartment",
    nameproject:"Majidi View",
    number:"2",
    area:"518 m",
    citys:"Erbil",
    property:"Apartment",
    address:"Erbil - koya road",
    id:"646dccb3792e1089f528d554",
    build: '11',
    Allbuild:'24',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },









  {
    id: 4,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Sky-View-B1-12-Floor',
    name: 'Apartment for Sale Sky View B1 12 Floor',
    nameproperty: 'Sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '6',
    bathrooms: '3',
    area: '80 meter',
    year: '2023',
    price: '23,920',
    agent: {
      image: '../images/customer/mahmood.webp',
      name: 'Mahmood Abdulla',
      phone: "tel:+9647501882006",
      email: 'mahmood@zest-property.com'
    },
  },
  {
    id: 4,
    type: 'House',
    url: 'House-for-Sale-Skyland1',
    name: 'house for Sale Skyland type 1 ',
    nameproperty: 'Skyland',
    build: '150 more',
    Allbuild: '1',
    image: '../images/property/skyland/skylandbg.jpg',
    imageLg: '../images/property/skyland/skylandbg.jpg',
    country: 'Iraq',
    address: 'Kirkuk - new traffic office',
    bedrooms: '2',
    bathrooms: '1',
    area: '200 meter',
    year: '2023',
    price: '69,000',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: 'Akam.a@zest-property.com'
    },
  },
  {
    id: 4,
    type: 'House',
    url: 'House-for-Sale-Skyland2',
    name: 'house for Sale Skyland type 2 ',
    nameproperty: 'Skyland',
    build: '150 more',
    Allbuild: '2',
    image: '../images/property/skyland/skylandbg.jpg',
    imageLg: '../images/property/skyland/skylandbg.jpg',
    country: 'Iraq',
    address: 'Kirkuk - new traffic office',
    bedrooms: '4',
    bathrooms: '1',
    area: '211 meter',
    year: '2023',
    price: '91,000',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: 'Akam.a@zest-property.com'
    },
  },
  {
    id: 4,
    type: 'House',
    url: 'house-for-Sale-Skyland-vip',
    name: 'house for Sale Skyland vip ',
    nameproperty: 'Skyland',
    build: '150 more',
    Allbuild: '2',
    image: '../images/property/skyland/skylandbg.jpg',
    imageLg: '../images/property/skyland/skylandbg.jpg',
    country: 'Iraq',
    address: 'Kirkuk - new traffic office',
    bedrooms: '4',
    bathrooms: '1',
    area: '270 meter',
    year: '2023',
    price: '110,000',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: 'Akam.a@zest-property.com'
    },
  },
  {
    id: 5,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Venus-Towers-A1-22-Floor',
    name: 'Apartment for Sale Venus Towers A1 22 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '2',
    bathrooms: '1',
    area: '105 meter',
    year: '2023',
    price: '16,096',
    agent: {
      image: '../images/customer/muhammadmajed.webp',
      name: 'Muhamad Majid',
      phone: "tel:+9647502422006",
      email: "mohammad.m@zest-property.com",
    },
  },  
  {
    id: 5,
    type: 'Apartment',
    url: 'property-for-Sale-alwedd-1',
    name: 'property for Sale alwedd 180',
    nameproperty: 'Alwedd ',
    build: '19',
    Allbuild: '15',
    image: '../images/property/alwedd/3.jpg',
    imageLg: '../images/property/alwedd/3.jpg',
    country: 'Iraq',
    address: 'Baghdad - Al-Amriya, Abu Ghraib Road',
    bedrooms: '3',
    bathrooms: '1',
    area: '180 meter',
    year: '2023',
    price: 'Call Us For Price',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Call center',
      phone: "tel:+9647702302006",
      email: "akam.a@zest-property.com",
    },
  },
  
  {
    id: 5,
    type: 'Apartment',
    url: 'property-for-Sale-alkhlood',
    name: 'property for Sale alkhlood',
    nameproperty: 'Alkhlood ',
    build: '4',
    Allbuild: '16',
    image: '../images/property/klood/khloodbg.jpg',
    imageLg: '../images/property/klood/khloodbg.jpg',
    country: 'Iraq',
    address: 'Baghdad - Al-hadl',
    bedrooms: '3',
    bathrooms: '1',
    area: '193 meter',
    year: '2023',
    price: 'Call Us For Price',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Call center',
      phone: "tel:+9647706212006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 5,
    type: 'Apartment',
    url: 'property-for-Sale-alwedd-2',
    name: 'property for Sale alwedd 200',
    nameproperty: 'Alwedd ',
    build: '19',
    Allbuild: '15',
    image: '../images/property/alwedd/3.jpg',
    imageLg: '../images/property/alwedd/3.jpg',
    country: 'Iraq',
    address: 'Baghdad - Al-Amriya, Abu Ghraib Road',
    bedrooms: '4',
    bathrooms: '1',
    area: '200 meter',
    year: '2023',
    price: 'Call Us For Price',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Call center',
      phone: "tel:+9647702302006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 5,
    type: 'Apartment',
    url: 'property-for-Sale-alwedd-villa',
    name: 'property for Sale alwedd Villa',
    nameproperty: 'Alwedd ',
    build: '53',
    Allbuild: '2',
    image: '../images/property/alwedd/villabg.jpg',
    imageLg: '../images/property/alwedd/villabg.jpg',
    country: 'Iraq',
    address: 'Baghdad - Al-Amriya, Abu Ghraib Road',
    bedrooms: '4',
    bathrooms: '1',
    area: '300 meter',
    year: '2023',
    price: 'Call Us For Price',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Call center',
      phone: "tel:+9647702302006",
      email: "akam.a@zest-property.com",
    },
  },
  {
    id: 6,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Nova-towers-A2-14-Floor',
    name: 'Apartment for Sale Nova towers A2 14 Floor',
    nameproperty: 'Nova towers',
    build: '5',
    Allbuild: '22',
    image: '../images/property/nova/ewr1.jpg',
    imageLg: '../images/property/nova/ewr1.jpg',
    country: 'Iraq',
    address: 'Erbil - bahrka road',
    bedrooms: '1',
    bathrooms: '1',
    area: '81 meter',
    year: '2023',
    price: '37,000',
    agent: {
      image: '../images/customer/shaho.webp',
      name: 'Shaho Dyaree',
      phone: "tel:+9647509632006",
      email: "shahoo.d@zest-property.com",
    },
  },
  {
    id: 9,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-A1-25-Floor',
    name: 'Apartment for Sale Venus Towers A1 25 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '1',
    bathrooms: '1',
    area: '72 meter',
    year: '2023',
    price: '10,317',
    agent: {
      image: '../images/customer/AkamAli.webp',
      name: 'Akam Ali',
      phone: "tel:+9647501762006",
      email: 'akam.a@zest-property.com'
    },
  },
  {
    id: 10,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Venus-Towers-A1-8-Floor',
    name: 'Apartment for Sale Venus Towers A1 8 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '1',
    bathrooms: '1',
    area: '72 meter',
    year: '2022',
    price: '11,397',
    agent: {
      image: '../images/customer/Aya.Alsaabb.webp',
      name: 'Aya Alsaabb',
      phone: "tel:+9647506322006",
      email: "aya.s@zest-property.com"
    },
  },
  {
    id: 11,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Sky-View-A3-31-Floor',
    name: 'Apartment for Sale Sky View A3 31 Floor',
    nameproperty: 'sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '2',
    bathrooms: '1',
    area: '120 meter',
    year: '2022',
    price: '35,856',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 13,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Sky-View-B3-37-Floor',
    name: 'Apartment for Sale Sky View B3 37 Floor',
    nameproperty: 'Sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '2',
    bathrooms: '1',
    area: '120 meter',
    year: '2022',
    price: '38,256',
    agent: {
      image: '../images/customer/mahmood.webp',
      name: 'Mahmood Abdulla',
      phone: "tel:+9647501882006",
      email: 'mahmood@zest-property.com'
    },
  },
  {
    id: 56,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-A-6-floor",
    name: "Apartment for Sale North Holland A 6",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '210 m²',
    year: '2023',
    price: '28,203',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-D-10-140",
    name: "Apartment for Sale North Holland D 10",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '3',
    bathrooms: '1',
    area: '140 m²',
    year: '2023',
    price: 'Call us',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-D-13-140",
    name: "Apartment for Sale North Holland D 13",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '3',
    bathrooms: '1',
    area: '140 m²',
    year: '2023',
    price: 'Call us',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-F-14-140",
    name: "Apartment for Sale North Holland D 14",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '3',
    bathrooms: '1',
    area: '140 m²',
    year: '2023',
    price: 'Call us',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 56,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-C-6",
    name: "Apartment for Sale North Holland C 6",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '2',
    bathrooms: '1',
    area: '140 m²',
    year: '2023',
    price: '18,802',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 14,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Venus-Towers-A2-14-Floor',
    name: 'Apartment for Sale Venus Towers A2 14 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '1',
    bathrooms: '1',
    area: '76 meter',
    year: '2022',
    price: '13,550',
    agent: {
      image: '../images/customer/muhammadmajed.webp',
      name: 'Muhammad Majed',
      phone: "tel:+9647502422006",
      email: "mohammad.m@zest-property.com",
    },
  },
  {
    id: 15,
    type: 'Apartment',
    url: 'Apartment-for-Sale-Sky-View-B3-22-Floor',
    name: 'Apartment for Sale Sky View B3 22 Floor',
    nameproperty: 'Sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '2',
    bathrooms: '1',
    area: '120 meter',
    year: '2022',
    price: '38,256',
    agent: {
      image: '../images/customer/aliagha.webp',
      name: 'Ali Agha',
      phone: "tel:+9647505912006",
      email: 'ali.a@zest-property.com',
    },
  },
  {
    id: 16,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-A1-13-Floor',
    name: 'Apartment for Sale Venus Towers A1 13 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '2',
    bathrooms: '1',
    area: '115 meter',
    year: '2022',
    price: '15,904',
    agent: {
      image: '../images/customer/muhammedrasul.webp',
      name: 'Muhammad Rasul',
      phone: "tel:+9647506912006",
      email: "M.Rasul@zest-property.com",
    },
  },
  {
    id: 17,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Sky-View-B3-7-Floor',
    name: 'Apartment for Sale Sky View B3 7 Floor',
    nameproperty: 'sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 meter',
    year: '2022',
    price: '23,120',
    agent: {
      image: '../images/customer/Aya.Asaab.webp',
      name: 'Aya Asaab',
      phone: "tel:+9647506322006",
      email: "aya.s@zest-property.com"
    },
  },
  {
    id: 18,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-A2-6-Floor',
    name: 'Apartment for Sale Venus Towers A2 6 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '1',
    bathrooms: '1',
    area: '67 meter',
    year: '2022',
    price: '12,951',
    agent: {
      image: '../images/customer/rasty.webp',
      name: 'Rasty Kayfi',
      phone: "tel:+9647507342006",
      email: "Rasty.kayfi@zest-property.com"
    }
  },
  {
    id: 19,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Sky-View-A1-7-Floor',
    name: 'Apartment for Sale Sky View A1 7 Floor',
    nameproperty: 'sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 meter',
    year: '2022',
    price: '34,400',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    }
  },
  {
    id: 20,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-A2-5-Floor',
    name: 'Apartment for Sale Venus Towers A2 5 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '1',
    bathrooms: '1',
    area: '67 meter',
    year: '2022',
    price: '12,951',
    agent: {
      image: '../images/customer/muhammadmajed.webp',
      name: 'Muhammad Majed',
      phone: "tel:+9647502422006",
      email: "mohammad.m@zest-property.com",
    }
  },
  {
    id: 21,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Sky-View-B2-6-Floor',
    name: 'Apartment for Sale Sky View B2 6 Floor',
    nameproperty: 'sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 meter',
    year: '2022',
    price: '23,120',
    agent: {
      image: '../images/customer/aliagha.webp',
      name: 'Ali Agha',
      phone: "tel:+9647505912006",
      email: 'ali.a@zest-property.com',
    }
  },
  {
    id: 22,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-B1-14-Floor',
    name: 'Apartment for Sale Venus Towers B1 14 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '2',
    bathrooms: '1',
    area: '105 meter',
    year: '2022',
    price: '15,571',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    }
  },
  {
    id: 23,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Sky-View-B2-10-Floor',
    name: 'Apartment for Sale Sky View B2 10 Floor',
    nameproperty: 'sky view',
    build: '6',
    Allbuild: '39',
    image: '../images/property/skyview/dsf1.jpg',
    imageLg: '../images/property/skyview/dsf1.jpg',
    country: 'Iraq',
    address: 'Erbil - in front of sami abdulrahman park',
    bedrooms: '1',
    bathrooms: '1',
    area: '80 meter',
    year: '2022',
    price: '23,120',
    agent: {
      image: '../images/customer/rasty.webp',
      name: 'Rasty Kayfi',
      phone: "tel:+9647507342006",
      email: "Rasty.kayfi@zest-property.com"
    }
  },
  {
    id: 24,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-B1-21-Floor',
    name: 'Apartment for Sale Venus Towers B1 21 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '2',
    bathrooms: '1',
    area: '115 meter',
    year: '2022',
    price: '17,054',
    agent: {
      image: '../images/customer/mahmood.webp',
      name: 'Mahmood Majed',
      phone: "tel:+9647501882006",
      email: "mohammad.m@zest-property.com",
    }
  },
  {
    id: 25,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-A2-17-Floor',
    name: 'Apartment for Sale Venus Towers A2 17 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '1',
    bathrooms: '1',
    area: '67 meter',
    year: '2022',
    price: '11,276',
    agent: {
      image: '../images/customer/aliagha.webp',
      name: 'Ali Agha',
      phone: "tel:+9647505912006",
      email: 'ali.a@zest-property.com',
    }
  },
  {
    id: 26,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-B3-13-Floor',
    name: 'Apartment for Sale Venus Towers B3 13 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - 120m in front of hawlery nwe',
    bedrooms: '2',
    bathrooms: '1',
    area: '105 meter',
    year: '2022',
    price: '16,096',
    agent: {
      image: '../images/customer/Aya.Alsaab.webp',
      name: 'Aya Alsaab',
      phone: "tel:+9647506322006",
      email: "aya.s@zest-property.com"
    }
  },
  {
    id: 27,
    type: 'Apartament',
    url: 'Apartment-for-Sale-Venus-Towers-A3-6-Floor',
    name: 'Apartment for Sale Venus Towers A3 6 Floor',
    nameproperty: 'Venus Towers ',
    build: '6',
    Allbuild: '30',
    image: '../images/property/venus/asdfaf1.jpg',
    imageLg: '../images/property/venus/asdfaf1.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '81 meter',
    year: '2022',
    price: '14,442',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    }
  },

  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-F-7-floor",
    name: "Apartment for Sale North Holland F 7",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-D-11",
    name: "Apartment for Sale North Holland D 11",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2023',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-13-90",
    name: "Apartment for Sale North Holland B 13",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2023',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-F-7-90",
    name: "Apartment for Sale North Holland F 7",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2023',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-F-8-90",
    name: "Apartment for Sale North Holland F 8",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2023',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-D-12-90",
    name: "Apartment for Sale North Holland D 12",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2023',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-10-96",
    name: "Apartment for Sale North Holland B 10",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '96 m²',
    year: '2023',
    price: '12,893',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 33,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-10",
    name: "Apartment for Sale North Holland B 10",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '3',
    bathrooms: '1',
    area: '210 m²',
    year: '2023',
    price: '28,203',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 38,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-5-floor",
    name: "Apartment for Sale North Holland B 5",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 39,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-5-floor",
    name: "Apartment for Sale North Holland B 5",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

  {
    id: 44,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-8",
    name: "Apartment for Sale North Holland B 8",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  //   {
  //   id: 45,
  //   type: 'Apartament',
  //   url: "Apartment-for-Sale-North-Holland-B-5",
  //   name: "Apartment for Sale North Holland B 5",
  //   nameproperty:'North Holland',
  //   build: '6',
  //   Allbuild: '20',
  //   image: '../images/property/north/north_holland_hd.jpg',
  //   imageLg: '../images/property/north/north_holland_hd.jpg',
  //   country: 'Iraq',
  //   address: 'Erbil - Mosul Road, opposite of Dream City ',
  //   bedrooms: '1',
  //   bathrooms: '1',
  //   area: '90 m²',
  //   year: '2022',
  //   price: '12,087',
  //   agent: {
  //     image: '../images/customer/kaywan.webp',
  //     name: 'Kaywan Kayfi',
  //     phone:"tel:+9647506662006",
  //     email:"kaiwankaify@zest-property.com"
  //   },
  // },
  {
    id: 41,
    type: 'Apartament',
    url: "Apartment-for-Sale-QAIWAN-MIRADOR-C-19-floor",
    name: "Apartment for Sale QAIWAN MIRADOR C 19",
    nameproperty: 'QAIWAN MIRADOR',
    build: '9',
    Allbuild: '29',
    image: '../images/property/qaywan/qaywan_hd.jpg',
    imageLg: '../images/property/qaywan/qaywan_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Pirmam Road, opposite of Grand Majidi Mall',
    bedrooms: '4',
    bathrooms: '1',
    area: '201.6 m²',
    year: '2022',
    price: '28,305',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 43,
    type: 'Apartament',
    url: "Apartment-for-Sale-QAIWAN-MIRADOR-B-6-201",
    name: "Apartment for Sale QAIWAN MIRADOR B 6",
    nameproperty: 'QAIWAN MIRADOR',
    build: '9',
    Allbuild: '29',
    image: '../images/property/qaywan/qaywan_hd.jpg',
    imageLg: '../images/property/qaywan/qaywan_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Pirmam Road, opposite of Grand Majidi Mall',
    bedrooms: '4',
    bathrooms: '1',
    area: '201.6 m²',
    year: '2022',
    price: '28,305',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 50,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-B-6",
    name: "Apartment for Sale North Holland B 6",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 52,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-A-7",
    name: "Apartment for Sale North Holland A 7",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },
  {
    id: 52,
    type: 'Apartament',
    url: "Apartment-for-Sale-North-Holland-A-8",
    name: "Apartment for Sale North Holland A 8",
    nameproperty: 'North Holland',
    build: '6',
    Allbuild: '20',
    image: '../images/property/north/north_holland_hd.jpg',
    imageLg: '../images/property/north/north_holland_hd.jpg',
    country: 'Iraq',
    address: 'Erbil - Mosul Road, opposite of Dream City ',
    bedrooms: '1',
    bathrooms: '1',
    area: '90 m²',
    year: '2022',
    price: '12,087',
    agent: {
      image: '../images/customer/kaywan.webp',
      name: 'Kaywan Kayfi',
      phone: "tel:+9647506662006",
      email: "kaiwankaify@zest-property.com"
    },
  },

]


export const images = [
  {
    mainlogo: '../images/logo.png'
  },
]














///////////////////////// ARABIC ///////////////////////////
export const arnav = [
  {
    text: "الصفحة الرئيسية",
    path: "/ar",
  },
  {
    text: "العقارات",
    path: "/ar/properties",
  },
  {
    text: "الوكلاء",
    path: "/ar/agents",
  },
  {
    text: "المشاريع",
    path: "/ar/projects",
  },
  {
    text: "عن شركتنا",
    path: "/ar/about",
  },
  {
    text: "الاتصال",
    path: "/ar/contact",
  },
]


export const arteam = [
  {
    list: "50",
    cover: "../images/customer/AkamAli.webp",
    address: "مشروع: سكاي لاند كركوك",
    name: "اكام علي",
    email: "mailto:akam.a@zest-property.com",
    phone: "tel:+9647501762006",
  },
  {
    list: "50",
    cover: "../images/customer/kaywan.webp",
    address: "المشروع: اربيل",
    name: "كيوان كيفي",
    email: "mailto:kaiwankaify@zest-property.com",
    phone: "tel:+9647506662006",
  },
  {
    list: "50",
    cover: "../images/customer/mriam.jpg",
    address: "المشروع: اربيل",
    name: "مريم موفق",
    email: "mailto:mriam.m@zest-property.com",
    phone: "tel:+9647505622006",
  },
  {
    list: "50",
    cover: "../images/customer/rasty.webp",
    address: "المشروع: اربيل",
    name: "راستي كيفي",
    email: "mailto:Rasty.kayfi@zest-property.com",
    phone: "tel:+9647507342006",
  },
  {
    list: "50",
    cover: "../images/customer/Aya.Alsaab.webp",
    address: "المشروع: اربيل",
    name: "ايه الصعب",
    email: "mailto:zest@zest-property.com",
    phone: "tel:+9647506662006",
  },
]

export const arallAgents = [
  {
    list: "50",
    cover: "../images/customer/AkamAli.webp",
    address: "مشروع: سكاي لاند كركوك",
    name: "اكام علي",
    email: "mailto:akam.a@zest-property.com",
    phone: "tel:+9647501762006",
  },
  {
    list: "50",
    cover: "../images/customer/kaywan.webp",
    address: "المشروع: اربيل",
    name: "كيوان كيفي",
    email: "mailto:kaiwankaify@zest-property.com",
    phone: "tel:+9647506662006",
  },
  {
    list: "50",
    cover: "../images/customer/mriam.jpg",
    address: "المشروع: اربيل",
    name: "مريم موفق",
    email: "mailto:mriam.m@zest-property.com",
    phone: "tel:+9647505622006",
  },
  {
    list: "50",
    cover: "../images/customer/abdulla.jpg",
    address: "المشروع: اربيل",
    name: "عبد الله فارس ",
    email: "mailto:abdulla.f@zest-property.com",
    phone: "tel:+9647506522006",
  },
  {
    list: "50",
    cover: "../images/customer/rasty.webp",
    address: "المشروع: اربيل",
    name: "راستي كيفي",
    email: "mailto:Rasty.kayfi@zest-property.com",
    phone: "tel:+9647507342006",
  },
  {
    list: "50",
    cover: "../images/customer/mahmood.webp",
    address: "المشروع: اربيل",
    name: "محمود عبدالله",
    email: "mailto:mahmood@zest-property.com",
    phone: "tel:+9647501882006",
  },
  {
    list: "50",
    cover: "../images/customer/shaho.webp",
    address: "المشروع: كركوك",
    name: "شاهو دياري",
    email: "mailto:shahoo.d@zest-property.com",
    phone: "tel:+9647509632006",
  },
  {
    list: "50",
    cover: "../images/customer/M.Sami.webp",
    address: "المشروع: كركوك",
    name: "محمد سامي",
    email: "mailto:m.sami@zest-property.com",
    phone: "tel:+9647708652006",
  },
  {
    list: "50",
    cover: "../images/customer/Aya.Alsaab.webp",
    address: "المشروع: اربيل",
    name: "ايه الصعب",
    email: "mailto:aya.s@zest-property.com",
    phone: "tel:+9647506322006",
  },
  {
    list: "50",
    cover: "../images/customer/HozanJumaa.webp",
    address: "المشروع: كركوك",
    name: "هوزان جمعة",
    email: "mailto:hozan.j@zest-property.com",
    phone: "tel:+9647509102006",
  },
  // {
  //   list: "50",
  //   cover: "../images/customer/muhammadmajed.webp",
  //   address: "المشروع: اربيل",
  //   name: "محمد مجيد",
  //   email: "mailto:mohammad.m@zest-property.com",
  //   phone:"tel:+9647502422006",
  // },

  // {
  //   list: "50",
  //   cover: "../images/customer/aliagha.webp",
  //   address: "المشروع: اربيل",
  //   name: "علي آغا",
  //   email: "mailto:ali.a@zest-property.com",
  //   phone:"tel:+9647505912006",
  // },
]










