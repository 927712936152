import React from 'react'

function pagenotfoud() {
  return (
    <div className='pagenotfound'>
      this page not avalabil go Home page
    </div>
  )
}

export default pagenotfoud
