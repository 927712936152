import React from "react"
import Projectcarts from "../pricing/Projectcarts"


function Projetctsprice() {
  return (
    <>
      <section className='sss'>
        <Projectcarts />
      </section>
    </>
  )
}

export default Projetctsprice
