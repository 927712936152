import "./App.css"
import Pages from "./components/pages/Pages"
import "./fonts/arabicfont.otf";



function App() {
  return (
  <Pages />
  )
}

export default App