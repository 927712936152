import React from 'react'

function ArLists() {
    return (
        <div className='navleft'>
            <div>
                <h6 style={{ margin: '10px 0' }}>موقع</h6>
                <div className='navp'>
                    <a href='/ar/projects/dubai'>دبي</a>
                    <a href='/ar/projects/baghdad'>بغداد</a>
                    <a href='/ar/projects/erbil'>اربيل</a>
                    <a href='/ar/projects/kirkuk'>كركوك</a>
                    <a href='/ar/projects/turkey'>ترکیا</a>
                </div>
            </div>
            <hr />
            <div>
                <h6>تطوير العقارات</h6>
                <div className='navp'>
                    <a href='/ar/samana-skyros'>سمانا سكايروس</a>
                    <a href='/ar/Majidi-View'>ماجدي فیو</a>
                    <a href='/ar/bianca'>بيانكا</a>
                    <a href='/ar/alwedd'>الود </a>
                    <a href='/ar/khlood'>الخلود </a>
                    <a href='/ar/almansour-city'>المنصور سيتي</a>
                    <a href='/ar/empire-world'>إمباير وورلد</a>
                    <a href='/ar/north-holland'>نورث هولند</a>
                    <a href='/ar/qaiwan-mirador'>قیوان میرادور</a>
                    <a href='/ar/lalav-group'>لالاف گروپ</a>
                    <a href='/ar/sky-land-kirkuk'>سکاي لاند</a>
                    <a href='/ar/damac-lagoons'>داماك لاگون</a>
                    <a href='/ar/azizi-riviera'>عزيزي ريفيرا</a>
                    <a href='/ar/high-class'>هاي كلاس</a>
                    <a href='/ar/damac-bay2'>داماك بي ٢</a>
                    <a href='/ar/bianca'>بیانکا</a>
                    <a href='/ar/sylvana'>سيلفانا </a>
                </div>
            </div>
        </div>
    )
}

export default ArLists